import { Modal } from 'antd';
import i18n from '@/locales/init';
import { login } from '../../utils/login';

let loginModalVisible = false;

export const LoginModal = {
  open: (cancel?: () => void) => {
    if (loginModalVisible) {
      return;
    }
    loginModalVisible = true;
    // 弹窗中断的情况下，清理选区
    if (document.activeElement?.tagName === 'INPUT' || document.activeElement?.tagName === 'TEXTAREA') {
      (document.activeElement as (HTMLInputElement | HTMLTextAreaElement)).blur();
    }
    console.log('[Current Cookie]', document.cookie);
    Modal.confirm({
      title: i18n.t('common.dauyan_account_invalid_before_login'),
      content: '',
      okText: i18n.t('common.dauyan_account_login'),
      cancelText: i18n.t('common.dauyan_cancel'),
      okButtonProps: {
        style: { outline: 'none', background: '#4A9E81' }
      },
      onCancel: () => {
        loginModalVisible = false;
        if (cancel) {
          cancel();
        }
      },
      onOk() {
        loginModalVisible = false;
        login();
      },
    });
  },
};

export const gotoLoginPage = () => {
  login();
};
