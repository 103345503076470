import React from 'react';
import dayjs from 'dayjs';
import { useMemoizedFn } from 'ahooks';
import classNames from 'classnames';
import { logCount } from '@/utils/logger/arms-config';
import i18n from '@/locales/init';
import { IWritingTask2Practice } from '@/api/history';
import lessModule from './item.module.less';

/**
 * 练习类型：1审题练习 2思路拓展练习 3分段练习 4整篇练习
 */
const getTypeText = (type: number) => {
  switch (type) {
    case 1: return i18n.t('common.dauyan_review_topic');
    case 2: return i18n.t('common.dauyan_idea_expansion_practice');
    case 3: return i18n.t('common.dauyan_segment_practice');
    default: return i18n.t('common.dauyan_full_page_practice');
  }
};

export interface IWritingTask2PracticeItemProps {
  item: IWritingTask2Practice;
  onHomePage?: boolean;
}

export const WritingTask2PracticeItem = React.memo<IWritingTask2PracticeItemProps>(({
  item, onHomePage
}) => {

  const handleClick = useMemoizedFn(() => {
    let url = '';
    logCount(`写作分段练习历史记录进入详情：${item.type}`);
    switch (item.type) {
      case 1: {
        url = `/question-review/${item.paper_id}/1?record_id=${item.record_id}`;
        break;
      }
      case 2: {
        url = `/writing-ideas/${item.paper_id}/1`;
        break;
      }
      case 3: {
        url = `/segmented/${item.paper_id}/1`;
        break;
      }
      case 4: {
        switch (item.step) {
          case 1: {
            url = `/question-review/${item.paper_id}/2`;
            break;
          }
          case 2: {
            url = `/writing-ideas/${item.paper_id}/2`;
            break;
          }
          case 3: {
            url = `/segmented/${item.paper_id}/2`;
            break;
          }
        }
        break;
      }
      default: {
        url = `/question-review/${item.paper_id}/2`;
        break;
      }
    }

    // TODO: 确认整篇练习下，不同状态的入口位置是否相同
    window.open(`${window.location.origin}/writing/#${url}`);
  });
  return <div className={classNames(lessModule.itemWrapper, onHomePage && lessModule.onHomePage)} onClick={handleClick}>
    <div className={lessModule.title}>{item.paper_title}</div>
    <div className={lessModule.content}>{item.paper_content}</div>
    <div className={lessModule.type}>{getTypeText(item.type)}</div>
    <div className={lessModule.time}>{dayjs(item.update_time * 1000).format('YYYY-MM-DD HH:mm')}</div>
  </div>;
});
