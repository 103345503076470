import Axios from 'axios';
import { AxiosResponseType, post } from '@/utils/network/axios-helper';

/**
 * 轮训检查评分是否完成
 */
// #region 轮训检查评分是否完成
export interface IFeedbackScoreParams {
  record_id: number;
}

export interface IFeedbackScoreRes {
  result: boolean;
  result_type: number[] | null;
}

// #endregion

export const checkFeedbackScore = async (id: number): Promise<AxiosResponseType<IFeedbackScoreRes>> => {
  return Axios.post('/api/exercise/get-record-score', { record_id: id });
};

/**
 * 获取练习记录
 */

// #region 获取练习记录
export interface IFeedbackInfoReq {
  id: number;
}

export interface Problem {
  /**
   * 问题内容
   */
  content: string;
  /**
   * 问题主键id
   */
  id: number;
  range: Range;
  ranges: Range[];
  location: { sentence: string; word: string; };
  locations: Array<{ sentence: string; word: string; }>;
  /**
   * 单词内容
   */
  text: string;
  /**
   * 问题标题
   */
  title: string;
  /**
   * 特效类型
   */
  type: string;
  /**
   * 归属criteria二级项目
   */
  pid: number;
}

export interface Range {
  /* 开始位置 */
  index: number;
  /* 长度 */
  length: number;
}

export interface ProbeList {
  /**
   * 回答
   */
  answer: string;
  /**
   * 追问id
   */
  id: number;
  /**
   * 问题
   */
  question: string;
}

export interface Problem {
  /* 问题内容 */
  content: string;
  /* 问题主键id */
  id: number;
  range: Range;
  /* 单词内容 */
  text: string;
  /* 问题标题 */
  title: string;
  /* 特效类型 */
  type: string;
  probe_list: ProbeList[];
}

export interface IFeedbackInfoRes {
  /* 题目标题 */
  title: string;
  /* 题目内容 */
  content: string;
  /* 提纲 */
  outline: string;
  /* 正文 */
  text: string;
  problem: Problem[];
  /** 加密后的ID，用于分享 */
  encode_id: number;
}

export const getFeedbackInfo = async (id: number): Promise<AxiosResponseType<any>> => {
  return Axios.post<IFeedbackInfoRes, AxiosResponseType<any>, IFeedbackInfoReq>('/api/exercise/get-record-info', { id });
};

/** 不鉴权的获取信息接口，id为加密后id */
export const getSharedFeedbackInfo = async (id: number): Promise<AxiosResponseType<any>> => {
  return Axios.post<IFeedbackInfoRes, AxiosResponseType<any>, IFeedbackInfoReq>('/api/exercise/get-share-record-info', { id });
};
// #endregion

/**
 * 获取评分细则
 */
// #region 获取评分细则
export interface IGetFeedbackDetailReq {
  record_id: number;
}

export interface CriterionSubItem {
  id: number;
  name: string;
  /* 批改问题数量 */
  problem_num: number;
  score: number;
  tips: string;
}

export interface Criterion {
  id: number;
  /* 名称 */
  name: string;
  /* 得分 */
  score: number;
  /* 鼠标放上后提示*/
  tips: string;
  children: CriterionSubItem[];
}

export interface IGetFeedbackDetailRes {
  /* 评分标准列表 */
  criteria: Criterion[];
  /* 评价与建议 */
  propose: string;
  /* 总得分 */
  score: number;
}

export const getFeedbackDetail = (id: number): Promise<AxiosResponseType<IGetFeedbackDetailRes>> => {
  return Axios.post<IGetFeedbackDetailRes, AxiosResponseType<IGetFeedbackDetailRes>, IGetFeedbackDetailReq>('/api/exercise/get-score-info', { record_id: id });
};

/** 不鉴权的获取评分细则接口， ID 是加密后的 record id */
export const getSharedFeedbackDetail = (id: number): Promise<AxiosResponseType<IGetFeedbackDetailRes>> => {
  return Axios.post<IGetFeedbackDetailRes, AxiosResponseType<IGetFeedbackDetailRes>, IGetFeedbackDetailReq>('/api/exercise/get-share-score-info', { record_id: id });
};
// #endregion

/**
 * 对评分失败的部分重新评分
 * @param id record id
 * @returns 
 */
export const regenerateScore = (id: number) => {
  return Axios.post('/api/exercise/re-generate-score', { record_id: id });
};

export const getCompareEssay = (id: number) => {
  return Axios.post('/api/exercise/get-compare-essay', { id: id });
};


export interface IFeedbackProbeReq {
  /**
   * 错误问题id
   */
  problem_id: number;
  /**
   * 追问内容
   */
  question: string;
}

export interface IFeedbackProbeRes {
  /**
   * 错误问题id
   */
  problem_id: number;
  /**
   * 追问id
   */
  question_id: string;
  answer: string;
}

/**
 * 评分追问
 * @deprecated
 * @param id record id
 * @returns 
 */
export const feedbackProblemProbe = (problem_id: number, question: string) => {
  return post<IFeedbackProbeRes>('/api/exercise/problem-probe', { problem_id, question });
};

/**
 * 范文
 */
export const wsGetModelTextHost = '/ws/exercise/generate-corrected';


/**
 * 导出PDF
 */
export interface IExportPDF_Req {
  record_id: number;
  /**
   * 1大作文批改 2小作文批改 3大作文分段练习批改 4大作文模考批改 5小作文模考批改
   */
  type: number;
}

export interface IExportPDF_Res {
}

export const exportPDF = (params: IExportPDF_Req) => {
  return post<IExportPDF_Res>('/api/exercise/export-soring-info-pdf', params);
};
