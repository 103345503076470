
export const downloadRemoteFile = (src: string, filename: string) => {

  // 创建一个隐藏的可下载链接
  const element = document.createElement('a');
  element.setAttribute('href', src);
  element.setAttribute('download', filename);

  // 触发点击
  document.body.appendChild(element);
  element.click();

  // 然后移除这个元素
  document.body.removeChild(element);
};
