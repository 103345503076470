import { useMemoizedFn } from 'ahooks';
import { useState } from 'react';
import { downloadRemoteFile } from '@/utils/local/download';
import { exportPDF } from '@/api/writing/feedback';
import { getWritingTask1EvaluationList, getWritingTask2EvaluationList } from '@/api/history';


export const usePDFStateCheck = (recordId: number, type: number) => {
  const [pdfUrl, setPdfUrl] = useState('');
  const [exporting, setExporting] = useState<boolean>(false);

  const download = useMemoizedFn((src: string) => {

    const match = src.match(/([^\/]+)$/);
    const fileName = match ? match[0] : 'download.pdf';
    downloadRemoteFile(src, fileName);

  });

  const checkPdf = useMemoizedFn(() => {
    const func = type === 1 ? getWritingTask2EvaluationList : getWritingTask1EvaluationList;
    func({ page: 0, page_size: 1, id: [recordId] }).then((res) => {
      if (res.code === 200 && res.data.list.length === 1 && !!res.data.list[0].score_file) {
        console.log('文件地址', res.data.list[0].score_file);
        setExporting(false);
        setPdfUrl(res.data.list[0].score_file);

        download(res.data.list[0].score_file);
        return;
      }
      setTimeout(() => {
        checkPdf();
      }, 5000);
    });
  });

  const exportPDFFile = useMemoizedFn(() => {
    if (exporting) {
      return;
    }
    if (pdfUrl) {
      download(pdfUrl);
      return;
    }

    setExporting(true);
    exportPDF({ record_id: recordId, type, }).then((res) => {
      if (res.code === 200) {
        checkPdf();
      } else {
        setExporting(false);
      }
    }).catch(() => {
      setExporting(false);
    });
  });

  return {
    /** 正在导出 */
    exporting,
    /** 导出pdf文件 */
    exportPDFFile,
    pdfUrl,
  };
};